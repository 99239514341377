/* eslint-disable no-unused-vars */
import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import { Navigate } from 'react-router';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/index')));
// ==============================|| ROUTING RENDER ||============================== //

const BaseRoutes = (user) => {

   const permissions = user ? user.permissions : [];

   const erp_capabilities = ['superadmin','admin','erp_admin','erp_dashboard'];
   const cms_capabilities = ['cms_admin','cms_view','cms_edit','cms_view_control_room'];

   const isErp = erp_capabilities.some(capability => permissions.includes(capability));
   const isCms = cms_capabilities.some(capability => permissions.includes(capability));

   return {
      path: '/',
      element: (
         <AuthGuard>
            <MainLayout />
         </AuthGuard>
      ),
      children: [
         {
            path: '/',
            element: isErp ? <DashboardDefault /> : isCms ? <Navigate to="/cms/control-room/eventi-in-corso/" replace /> : <p>Not Found</p>,
         }
      ]
   }
}

export default BaseRoutes;