/* eslint-disable no-unused-vars */
// third-party
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//icons
import
{
   faComment,
   faChartLine,
   faBug,
   faEnvelopeOpenText,
   faFileMagnifyingGlass
} from '@fortawesome/pro-regular-svg-icons';

//Components
import UnreadedTicketCounter from "components/UnreadedTicketCounter";

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const consoleMenu = {
   id: 'console',
   title: 'Console',
   type: 'group',
   permissions: [
      'superadmin',
      'admin',
      'erp_admin',
      'erp_ticket-supporto',
      'erp_attivita',
      'erp_anomalie',
      'erp_comunicazioni-azienda'
   ],
   children: [
      {
         id: 'ticket-supporto',
         title: 'Ticket supporto',
         pageTitle: 'Ticket supporto',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin','erp_ticket-supporto'],
         url: '/console/ticket-supporto',
         chip: <UnreadedTicketCounter/>,
         icon: () => <FontAwesomeIcon icon={faComment} size="lg" />,
      },
      {
         id: 'console-attivita',
         title: 'Attività',
         pageTitle: 'Attività',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin', 'erp_attivita'],
         url: '/console/attivita',
         icon: () => <FontAwesomeIcon icon={faChartLine} size="lg" />,
      },
      {
         id: 'console-anomalie',
         title: 'Anomalie',
         pageTitle: 'Anomalie',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin', 'erp_anomalie'],
         url: '/console/anomalie',
         icon: () => <FontAwesomeIcon icon={faBug} size="lg" />,
      },
      {
         id: 'console-comunicazioni-azienda',
         title: 'Comunicazioni azienda',
         pageTitle: 'Comunicazioni azienda',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin', 'erp_comunicazioni-azienda'],
         url: '/console/comunicazioni-azienda',
         icon: () => <FontAwesomeIcon icon={faEnvelopeOpenText} size="lg" />,
      },
      {
         id: 'logs',
         title: 'Logs',
         pageTitle: 'Logs',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin'],
         url: '/console/logs',
         icon: () => <FontAwesomeIcon icon={faFileMagnifyingGlass} size="lg" />,
      },
   ]
};

export default consoleMenu;
