import { useState, useEffect } from 'react'
import { useFetch } from "use-http";

import
{

   Chip
} from '@mui/material';

const UnreadedTicketCounter = () =>
{
   const fetcher = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });
   const [unreaded, setUnreaded] = useState(0);

   useEffect(() =>
   {

      let interval;

      const fetchUnreaded = async () =>
      {
         const num = await fetcher.get('/api/comunicazioni/summary/');
         console.log('numnum', num);

         if (fetcher.error)
         {
            console.log('Problema durante il recupero dei dati per il controllo delle anomalie', fetcher.error);
            return null;
         }

         setUnreaded(num?.result);
      }

      interval = setInterval(() =>
      {
         fetchUnreaded();
      }, 60 * 2 * 1000);

      fetchUnreaded();

      return () =>
      {
         clearInterval(interval);
      }

   }, []);

   return (
      unreaded ? (
         <Chip
            label={unreaded}
            color='error'
         />
      ) : null
      
   )
}

export default UnreadedTicketCounter;