/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteProtection from './RouteProtection';

//CONSOLE
const ConsoleTicketSupporto = Loadable(lazy(() => import('pages/console/ticket-supporto')));
const ConsoleAttivita = Loadable(lazy(() => import('pages/console/attivita')));
const ConsoleAnomalie = Loadable(lazy(() => import('pages/console/anomalie')));
const ConsoleComunicazioniAzienda = Loadable(lazy(() => import('pages/console/comunicazioni-azienda')));
const ConsoleLogs = Loadable(lazy(() => import('pages/console/logs')));

// ==============================|| MAIN ROUTING ||============================== //
const ConsoleRoutes = (user) =>
{
   return {
      path: '/',
      children: [
         {
            path: '/',
            element: (
               <AuthGuard>
                  <MainLayout />
               </AuthGuard>
            ),
            children: [
               {
                  path: 'console',
                  children: [
                     {
                        path: 'ticket-supporto',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin','erp_ticket-supporto']}
                           >
                              <ConsoleTicketSupporto />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'attivita',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_attivita']}
                           >
                              <ConsoleAttivita />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'anomalie',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_anomalie']}
                           >
                              <ConsoleAnomalie />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'comunicazioni-azienda',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_comunicazioni-azienda']}
                           >
                              <ConsoleComunicazioniAzienda />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'logs',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin']}
                           >
                              <ConsoleLogs/>
                           </RouteProtection>
                        )
                     }
                  ]
               },
            ]
         },
         {
            path: '*',
            element: (
               <p>Not found</p>
            )
         }
      ]
   }
}

export default ConsoleRoutes;
